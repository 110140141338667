import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about" className="min-h-screen flex items-center justify-center py-20">
      <div className="max-w-3xl mx-auto text-center px-4">
        <h2 className="text-3xl font-bold mb-8">About Me</h2>
        <p className="text-lg text-gray-300 mb-6">
          As a Data Scientist, I specialize in transforming complex data into actionable insights. My journey spans from property management to financial services, always focusing on leveraging data to drive results.
        </p>
        <p className="text-lg text-gray-300">
          Currently leading Enterprise Data & Data Governance at Egon Zehnder, I've integrated cutting-edge technologies like ML, NLP, and GPT. My passion lies in solving data puzzles and pushing the boundaries of what's possible with data science.
        </p>
      </div>
    </section>
  );
};

export default About;
