import React from 'react';
import RevealOnScroll from '../components/RevealOnScroll';

interface Project {
  title: string;
  description: string;
  technologies: string[];
  link: string;
}

const projects: Project[] = [
  {
    title: "NVDA-Stock-Prediction",
    description: "This project aims to predict whether NVIDIA Corporation (NVDA) stock price will increase or decrease the next day using a Random Forest classifier. The model uses historical stock data to make predictions based on various technical indicators. I personally have been following NVDA hype on r/wallstreetbets way too much, so thought I would make this quick project.",
    technologies: ["Python", "Machine Learning", "Random Forest", "Stock Analysis"],
    link: "https://github.com/shantanudebnath12/NVDA-Stock-Prediction"
  },
  {
    title: "Portfolio Website",
    description: "A responsive personal portfolio website showcasing my skills and projects.",
    technologies: ["React", "TypeScript", "Tailwind CSS"],
    link: "#"
  },
  {
    title: "Discord Bot",
    description: "A sophisticated Discord bot designed to assist users in navigating the complex immigration laws of Canada, providing real-time information, answering common queries, and offering guidance on the application process.",
    technologies: ["Python", "Discord.py", "AWS EC2", "Docker"],
    link: "https://github.com/shantanudebnath12/discord-bot-immigration"
  },
  {
    title: "Algorithmic Trading with Machine Learning",
    description: "This project builds an algorithmic trading system using machine learning on S&P 500 data. It computes stock betas through Rolling OLS regression and is trained on features like price returns, trading volumes, volatility, and Fama-French factors to enhance trading decisions.",
    technologies: ["Python", "Machine Learning", "Scikit-Learn", "Pandas", "Numpy", "YFinance"],
    link: "https://github.com/shantanudebnath12/algorithmic-trading-system"
  }
];

const Projects: React.FC = () => {
  return (
    <section id="projects" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-8 text-center text-white">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <RevealOnScroll key={index}>
              <div className="bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 h-[400px] flex flex-col">
                <h3 className="text-2xl font-semibold mb-2 text-white">{project.title}</h3>
                <p className="text-gray-300 mb-4 overflow-y-auto flex-grow">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, techIndex) => (
                    <span key={techIndex} className="bg-gray-700 text-gray-300 px-2 py-1 rounded text-sm">
                      {tech}
                    </span>
                  ))}
                </div>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 transition-colors duration-300 mt-auto"
                >
                  View Project
                </a>
              </div>
            </RevealOnScroll>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
