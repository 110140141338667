import React from 'react';
import { FaGraduationCap, FaBriefcase } from 'react-icons/fa';
import RevealOnScroll from '../components/RevealOnScroll';

interface ExperienceItem {
  title: string;
  company: string;
  location: string;
  date: string;
  summary: string;
  icon: 'education' | 'work';
}

const experienceData: ExperienceItem[] = [
  {
    title: 'Data Scientist - Enterprise Data & Data Governance',
    company: 'Egon Zehnder',
    location: 'Montreal, QC',
    date: 'April 2023 - Present',
    summary: 'Led a team managing global data cleansing and preparation. Managed transition to Azure-based cloud CRM, improving data governance. Spearheaded ML model fine-tuning, NLP, and Semantic Search projects. Integrated enterprise GPT for improved data cleansing efficiency.',
    icon: 'work',
  },
  {
    title: 'Data Migration Analyst',
    company: 'Concordia University',
    location: 'Montreal, QC',
    date: 'January 2023 - April 2023',
    summary: 'Managed data migration for Corporate Risk Management division, utilizing SQL and Python for efficient data cleaning and transfer. Collaborated with cross-functional teams to understand and meet data requirements.',
    icon: 'work',
  },
  {
    title: 'Associate Data Analyst - Revenue Management',
    company: 'Lifehouse',
    location: 'Montreal, QC',
    date: 'January 2022 - December 2022',
    summary: 'Implemented pricing strategies resulting in 15% increase in average revenue. Developed data models and dashboards using Tableau and Excel, analyzing data across 12 hotels in USA and Mexico.',
    icon: 'work',
  },
  {
    title: 'Business Analyst Intern',
    company: 'Holt Cypher',
    location: 'Montreal, QC',
    date: 'September 2021 - December 2021',
    summary: 'Conducted user research, reducing reported issues by 25%. Assisted in developing new features for online banking platform and helped onboard first users of the platform.',
    icon: 'work',
  },
  {
    title: 'Business Operations Associate',
    company: 'Groupe Tourbee Inc.',
    location: 'Montreal, QC',
    date: 'September 2018 - October 2020',
    summary: 'Helped Property Management business reach $2,000,000 in Revenue. Developed automation processes, reducing tenant turnover by 25% and increasing occupancy rate by 20%.',
    icon: 'work',
  },
  {
    title: 'Bachelor of Commerce; Business Technology Management',
    company: 'Concordia University',
    location: 'Montreal, QC',
    date: 'Graduated',
    summary: '',
    icon: 'education',
  },
];

const Experience: React.FC = () => {
  return (
    <section id="experience" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-white">Experience</h2>
        <div className="relative">
          {/* Vertical line (visible only on md and larger screens) */}
          <div className="absolute left-1/2 transform -translate-x-1/2 w-0.5 h-full bg-gray-600 hidden md:block"></div>
          
          {/* Experience items */}
          <div className="space-y-12 md:space-y-24">
            {experienceData.map((item, index) => (
              <RevealOnScroll key={index}>
                <div className={`flex flex-col md:flex-row items-center ${index % 2 === 0 ? 'md:flex-row-reverse' : ''}`}>
                  <div className="w-full md:w-5/12 text-center mb-4 md:mb-0">
                    <div className="px-4">
                      <h3 className="text-xl font-semibold text-white">{item.title}</h3>
                      <p className="text-gray-300">{item.company}</p>
                      <p className="text-gray-300">{item.location}</p>
                      <p className="text-gray-300 mt-1">{item.date}</p>
                      {item.summary && (
                        <p className="text-gray-200 mt-2">{item.summary}</p>
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-2/12 flex justify-center my-4 md:my-0">
                    <div className="w-12 h-12 bg-[#111A27] rounded-full flex items-center justify-center z-10">
                      {item.icon === 'education' ? (
                        <FaGraduationCap className="text-white text-xl" />
                      ) : (
                        <FaBriefcase className="text-white text-xl" />
                      )}
                    </div>
                  </div>
                  <div className="w-full md:w-5/12"></div>
                </div>
              </RevealOnScroll>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
