// src/sections/Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="text-center py-6 bg-gray-900 text-gray-400">
      <p className="text-sm">Built and designed by Shantanu Debnath</p>
      <p className="text-xs mt-1">All rights reserved. ©{new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
