import React, { useState, useEffect, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, Github, Linkedin, Mail } from 'lucide-react';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import Navigation from './components/Navigation';
import About from './sections/About';
import Experience from './sections/Experience';
import Projects from './sections/Projects';
import Skills from './sections/Skills';
import Contact from './sections/Contact';
import RevealOnScroll from './components/RevealOnScroll';
import Footer from './sections/Footer'; // Add this import
import { animateScroll as scroll } from 'react-scroll';

const Portfolio: React.FC = () => {
  const [activeSection, setActiveSection] = useState('home');

  useLayoutEffect(() => {
    // Scroll to top on initial load and refresh
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Scroll to top on page refresh
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const handleScroll = () => {
      const sections = ['home', 'about', 'experience', 'projects', 'skills', 'contact'];
      let currentSection = 'home';

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
          if (rect.top <= 100 || (section === 'contact' && isAtBottom)) {
            currentSection = section;
            break;
          }

        }
      }

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const navbarHeight = 80; // Adjust this value based on your navbar height
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - navbarHeight;

      scroll.scrollTo(offsetPosition, {
        duration: 800,
        smooth: 'easeInOutQuart',
      });
    }
  };

  return (
    <div className="bg-gradient-to-b from-green-900 to-gray-900 text-white min-h-screen flex flex-col">
      <Navigation activeSection={activeSection} scrollTo={scrollTo} />

      <div className="flex-grow pt-16">
        <section id="home" className="min-h-screen flex items-center justify-center relative">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-5xl font-bold mb-4"
            >
              Shantanu Debnath
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-xl text-gray-300 mb-8"
            >
              Data Scientist
            </motion.p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex justify-center space-x-4 mb-8"
            >
              <a href="https://github.com/shantanudebnath12" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white"><Github /></a>
              <a href="https://www.linkedin.com/in/shantanu-debnath-512463129/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white"><Linkedin /></a>
              <a href="mailto:shantanudebnath12@gmail.com" className="text-gray-300 hover:text-white"><Mail /></a>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <a
                href="mailto:shantanudebnath12@gmail.com"
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105"
              >
                <Mail className="mr-2" />
                Say HI!
              </a>
            </motion.div>
          </div>
          <div className="absolute bottom-24 left-0 right-0 flex justify-center">
            <ChevronDown className="w-6 h-6 text-gray-300 animate-bounce" />
          </div>
        </section>

        <RevealOnScroll>
          <section id="about" className="min-h-screen">
            <About />
          </section>
        </RevealOnScroll>
        <RevealOnScroll>
          <section id="experience" className="min-h-screen">
            <Experience />
          </section>
        </RevealOnScroll>
        <RevealOnScroll>
          <section id="projects" className="min-h-screen">
            <Projects />
          </section>
        </RevealOnScroll>
        <RevealOnScroll>
          <section id="skills" className="min-h-screen">
            <Skills />
          </section>
        </RevealOnScroll>
        <RevealOnScroll>
          <div className="pb-32">
            <Contact />
          </div>
        </RevealOnScroll>
      </div>

      <Footer />
      <Analytics /> 
      <SpeedInsights />
    </div>
  );
};

export default Portfolio;
