import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

interface NavigationProps {
  activeSection: string;
  scrollTo: (id: string) => void;
}

// Custom hook to track scroll direction
const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState("up");
  const [prevOffset, setPrevOffset] = useState(0);
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > prevOffset && currentScrollPos > 10) {
      setVisible(false);
      setScrollDirection("down");
    } else {
      setVisible(true);
      setScrollDirection("up");
    }
    setPrevOffset(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => window.removeEventListener("scroll", toggleVisible);
  }, [prevOffset]);

  return { scrollDirection, visible };
};

const Navigation: React.FC<NavigationProps> = ({ activeSection, scrollTo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { visible } = useScrollDirection();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust this breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Toggle body class to control background opacity
    document.body.classList.toggle('menu-open');
  };

  const navItems = ['Home', 'About', 'Experience', 'Projects', 'Skills', 'Contact'];

  return (
    <>
      <nav className={`fixed top-0 left-0 right-0 z-50 bg-gradient-to-b from-green-900/80 to-green-900/0 pt-4 pb-4 transition-transform duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
        {isMobile ? (
          <div className="flex justify-center relative">
            <button onClick={toggleMenu} className="text-white p-2 rounded-full bg-green-700/90 hover:bg-green-600/90 transition-colors duration-200">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            {isMenuOpen && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-green-800/95 backdrop-blur-sm rounded-lg shadow-lg py-2 px-4 w-64">
                <ul className="flex flex-col items-center">
                  {navItems.map((item) => (
                    <li key={item} className="my-2 w-full">
                      <button
                        onClick={() => {
                          scrollTo(item.toLowerCase());
                          toggleMenu();
                        }}
                        className={`w-full text-sm font-medium py-2 px-4 rounded transition-colors duration-200 ${
                          activeSection === item.toLowerCase() 
                            ? 'bg-green-600/80 text-white' 
                            : 'text-gray-300 hover:bg-green-700/80 hover:text-white'
                        }`}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <ul className="flex justify-center space-x-6">
            {navItems.map((item) => (
              <li key={item}>
                <button
                  onClick={() => scrollTo(item.toLowerCase())}
                  className={`text-sm font-medium transition-colors duration-200 ${
                    activeSection === item.toLowerCase() ? 'text-green-300' : 'text-gray-300 hover:text-white'
                  }`}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        )}
      </nav>
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black/50 z-40" onClick={toggleMenu}></div>
      )}
    </>
  );
};

export default Navigation;
