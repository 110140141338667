import React from 'react';

const Skills: React.FC = () => {
  const skillCategories = [
    {
      category: "Languages",
      skills: ["SQL", "Python", "JavaScript"]
    },
    {
      category: "Python Libraries",
      skills: ["Django", "Matplotlib", "Pandas", "NumPy", "Scikit-Learn", "Selenium", "PyTorch"]
    },
    {
      category: "Tools & Platforms",
      skills: ["Tableau", "PowerBI", "Azure (DevOps, Synapse, Cognitive Search", "MongoDB", "Docker", "Git", "Hadoop", "Jira"]
    }
  ];

  return (
    <section id="skills" className="min-h-screen flex items-center justify-center py-20">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-12">Skills</h2>
        {skillCategories.map((category, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-xl font-semibold mb-4">{category.category}</h3>
            <div className="flex flex-wrap justify-center gap-4">
              {category.skills.map((skill, skillIndex) => (
                <span 
                  key={skillIndex} 
                  className="skill-box bg-green-800 bg-opacity-50 text-sm font-semibold px-3 py-1 rounded"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
