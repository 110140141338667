import React from 'react';
import { Mail, Linkedin, Github } from 'lucide-react';

const Contact: React.FC = () => {
  return (
    <section id="contact" className="py-16 pb-32"> {/* Add pb-32 for extra bottom padding */}
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Get In Touch</h2>
        <p className="text-lg text-gray-300 mb-8">
          I'm always open to new opportunities and collaborations. Feel free to reach out!
        </p>
        <div className="flex justify-center space-x-6 mb-8">
          <a href="mailto:shantanudebnath12@gmail.com" className="text-gray-300 hover:text-white">
            <Mail size={24} />
          </a>
          <a href="https://www.linkedin.com/in/shantanu-debnath-512463129/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">
            <Linkedin size={24} />
          </a>
          <a href="https://github.com/shantanudebnath12" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white">
            <Github size={24} />
          </a>
        </div>
        <a
          href="mailto:shantanudebnath12@gmail.com"
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full inline-flex items-center transition duration-300 ease-in-out transform hover:scale-105"
        >
          <Mail className="mr-2" />
          Send a Message
        </a>
      </div>
    </section>
  );
};

export default Contact;
